import PageRenderer from 'src/components/page-renderer/page-renderer.component';
import ssrHelper, {
  ENVIRONMENTS,
  PageData,
  SSR_STATUS_CODES,
  currentEnvironment,
  isPreviewMode,
  isTravelPlusHomepage,
} from 'src/utils/ssr-helper';
import PageHead from 'src/components/page-head/page-head.component';
import { useContext } from 'react';
import { VAAContext } from './_app';
import styles from 'styles/home.module.less';
import PreviewBannerComponent from 'src/components/preview-banner/preview.banner-component';
import CustomErrorComponent from 'src/components/custom-error/custom-error.component';
import PageESIWrapper from 'src/components/page-esi-wrapper/page-esi-wrapper.component';
import { GetServerSidePropsContext } from 'next';

export const getStaticProps = async ({ locale }: GetServerSidePropsContext) => {
  if (process.env.NEXT_PUBLIC_AEM_MODE === 'preview') {
    return {
      notFound: true,
    };
  }

  let revalidate = 30;
  if (currentEnvironment === ENVIRONMENTS.PROD) {
    revalidate = 60 * 5;
  }
  if (isPreviewMode) {
    revalidate = 5;
  }

  const pageData = await ssrHelper('/', locale);

  if (
    pageData.status === SSR_STATUS_CODES.ERROR &&
    pageData.errorCode === 404
  ) {
    return {
      notFound: true,
    };
  }

  if (
    (process.env.NEXT_PUBLIC_ENVIRONMENT_NAME as string).toLowerCase() !== 'grn'
  ) {
    if (
      pageData &&
      pageData.pageHeader &&
      pageData.pageHeader.canonicalUrl === ''
    )
      pageData.pageHeader.canonicalUrl =
        process.env.NEXT_PUBLIC_BRAND_NAME === 'vhols'
          ? 'https://virginholidays.co.uk/'
          : 'https://virginatlantic.com/';
  }

  return { props: pageData, revalidate };
};

export default function Page({
  status,
  errorCode,
  errorMessage,
  pageHeader,
  content,
  localeInfo,
}: PageData) {
  const vaaContext = useContext(VAAContext);

  if (pageHeader) {
    pageHeader.isHomepage = true;
    if (localeInfo) {
      pageHeader.localeInfo = localeInfo;
    }
  }

  if (status === 'error')
    return (
      <>
        {pageHeader && <PageHead {...pageHeader} />}
        <CustomErrorComponent
          errorCode={errorCode}
          errorMessage={errorMessage}
        />
      </>
    );

  return (
    <>
      {pageHeader && <PageHead {...pageHeader} />}
      <PageESIWrapper
        isTravelPlus={isTravelPlusHomepage}
        searchPanelConfig={pageHeader?.searchPanelConfig}
        localeInfo={localeInfo}
      >
        {isPreviewMode && <PreviewBannerComponent />}
        <div
          className={styles[`theme-${vaaContext.theme}`]}
          style={{ overflow: 'auto', overflowX: 'hidden' }}
        >
          <PageRenderer items={content} />
        </div>
      </PageESIWrapper>
    </>
  );
}
